import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { ComponentProps, MouseEvent, useId } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement } from '../../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Anchor, RouterLink, RouterNavLink } from '../../Anchor/Anchor.tsx';
import { IconName } from '../../Icons/types.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import {
  A11yLabelingPropsExtension,
  RequiredA11yLabellingProps,
} from '../../_utils/ariaLabelingProps.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonBadge, getButtonBadgeVariables } from '../ButtonBadge.tsx';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { getBadgeSpacing } from '../components/StyledButtonBadgePositioner.tsx';
import { StyledIconButton } from '../components/StyledIconButton.tsx';
import { getTokens, shouldWrapperHandleFocus } from '../utils/stylingUtils.ts';
import { StyledIconButtonIcon } from './IconButtonIcon.tsx';
import { IconButtonState } from './IconButtonState.ts';

type Props = TooltipPropsExtension &
  RequiredA11yLabellingProps &
  Pick<A11yLabelingPropsExtension, 'aria-describedby'> &
  Readonly<{
    activated?: boolean;
    badgeValue?: number;
    buttonState?: IconButtonState;
    buttonStyle: ButtonStyle;
    className?: string;
    component?: 'button' | typeof Anchor | typeof RouterLink | typeof RouterNavLink;
    destructive?: boolean;
    disableTabulator?: boolean;
    iconName: IconName;
    id?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    size: ButtonSize;
    tooltipPlacement: Placement;
    tooltipText: string;
    type?: ComponentProps<typeof StyledIconButton>['type'];
  }>;

const isIconButtonDisabled = (buttonState: IconButtonState) => {
  return buttonState === 'disabled' || buttonState === 'in-progress';
};

const StyledFocusWrapper = styled.div.attrs(getTokens)<{
  readonly $activated?: boolean;
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly $destructive?: boolean;
  readonly $disabled?: boolean;
  readonly isFocusVisible: boolean;
}>`
  position: relative;
  display: inline-block;
  border-radius: ${px(BorderRadius.Pill)};

  ${getBadgeSpacing};
  ${getButtonBadgeVariables};

  &:before {
    content: '';
    position: absolute;
    inset: ${px(-1 * offsetFocus)};
    border-radius: inherit;

    pointer-events: none;
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      activated,
      badgeValue,
      buttonState = 'default',
      buttonStyle,
      className,
      component = 'button',
      destructive,
      disableTabulator,
      iconName,
      id,
      onClick,
      size,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement,
      tooltipShortcuts,
      tooltipText,
      'aria-describedby': ariaDescribedby = '',
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const disabled = isIconButtonDisabled(buttonState);
    const { isFocusVisible, focusProps } = useOurFocusRing(disabled);
    const { isHovered, hoverProps } = useHover({});
    const tooltipId = useId();
    const shouldShowTooltip = isFocusVisible || isHovered;

    return (
      <Tooltip
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        placement={tooltipPlacement}
        shortcuts={tooltipShortcuts}
        tippyOptions={disabledAriaTippyOptions}
        text={activated ? '' : tooltipText}
        id={tooltipId}
        visible={shouldShowTooltip}
      >
        <StyledFocusWrapper
          $activated={activated}
          $buttonStyle={buttonStyle}
          $destructive={destructive}
          $disabled={disabled}
          $size={size}
          isFocusVisible={shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
        >
          <StyledIconButton
            $activated={activated}
            $buttonStyle={buttonStyle}
            $destructive={destructive}
            $isFocusVisible={!shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
            $size={size}
            aria-describedby={mergeAriaDescribedBy(
              ariaDescribedby,
              shouldShowTooltip ? tooltipId : '',
            )}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            as={component}
            className={className}
            disabled={disabled}
            id={id}
            onClick={onClick}
            ref={forwardedRef}
            tabIndex={disableTabulator ? -1 : undefined}
            {...mergeProps(focusProps, hoverProps, otherProps)}
            {...getDataUiComponentAttribute(IconButton)}
          >
            <StyledIconButtonIcon
              iconName={iconName}
              buttonStyle={buttonStyle}
              buttonState={buttonState}
              size={size}
              disabled={disabled}
              destructive={destructive}
              $activated={activated}
            />
            {!!badgeValue && <ButtonBadge value={badgeValue} />}
          </StyledIconButton>
        </StyledFocusWrapper>
      </Tooltip>
    );
  },
);

IconButton.displayName = 'IconButton';
