import { useAttachRef } from '@kontent-ai/hooks';
import { useFocusRing } from '@react-aria/focus';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { forwardRef, useEffect, useId, useState } from 'react';
import { ShortcutsConfig, useHotkeys } from '../../hooks/useHotkeys.tsx';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../Tooltip/constants.ts';
import { A11yLabelingPropsExtension } from '../_utils/ariaLabelingProps.ts';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { HintAppearanceType } from './HintStyle.ts';
import { StyledHint } from './StyledHint.ts';

type Props = Pick<TooltipPropsExtension, 'tooltipPlacement'> &
  Pick<A11yLabelingPropsExtension, 'aria-label'> & {
    readonly tooltipText: string;
    readonly hintStyle?: HintAppearanceType;
  };

export const Hint = forwardRef<HTMLButtonElement, Props>(
  (
    {
      'aria-label': ariaLabel = 'Activate to show more',
      hintStyle = 'default',
      tooltipPlacement = 'top',
      tooltipText,
      ...otherProps
    },
    ref,
  ) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const { isFocusVisible, focusProps } = useFocusRing();

    useEffect(() => {
      setIsTooltipVisible(isFocusVisible);
    }, [isFocusVisible]);

    const { hoverProps, isHovered } = useHover({
      isDisabled: isFocusVisible,
      onHoverChange: () => {
        setIsTooltipVisible((prev) => !prev);
      },
    });

    const { pressProps } = usePress({
      isDisabled: isHovered,
      onPress: (event) => {
        if (event.pointerType === 'touch' || event.pointerType === 'keyboard') {
          setIsTooltipVisible((prev) => !prev);
        }
      },
    });

    const { refObject, refToForward } = useAttachRef(ref);
    useHotkeys(
      {
        [ShortcutsConfig.Escape]: (event) => {
          if (isTooltipVisible) {
            setIsTooltipVisible(false);
            event.preventDefault();
          }
        },
      },
      { isDisabled: !isTooltipVisible, ref: refObject },
    );

    const tooltipId = useId();

    return (
      <Tooltip
        placement={tooltipPlacement}
        text={tooltipText}
        visible={isTooltipVisible}
        id={tooltipId}
        tippyOptions={disabledAriaTippyOptions}
      >
        <div
          css={`
          display: inline-block;
        `}
        >
          <StyledHint
            aria-describedby={isTooltipVisible ? tooltipId : undefined}
            aria-label={ariaLabel}
            hintStyle={hintStyle}
            isFocusVisible={isFocusVisible}
            ref={refToForward}
            {...mergeProps(focusProps, hoverProps, pressProps, otherProps)}
            {...getDataUiComponentAttribute(Hint)}
          >
            <Icons.QuestionCircle size={IconSize.S} />
          </StyledHint>
        </div>
      </Tooltip>
    );
  },
);

Hint.displayName = 'Hint';
