import React, {
  HTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler,
  ComponentProps,
} from 'react';
import { Box } from '../../layout/Box/Box.tsx';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { GradientType } from '../../tokens/quarks/gradients.ts';
import { isGradient } from '../../utils/gradient.ts';

type ColorCircleWithoutHTMLAttrsProps = Readonly<{
  className?: string;
  color: BaseColor | GradientType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  size: number;
  tabIndex?: number;
}>;

export interface IColorCircleProps
  extends ColorCircleWithoutHTMLAttrsProps,
    Pick<ComponentProps<typeof Box>, 'border' | 'position'>,
    Omit<
      HTMLAttributes<HTMLElement>,
      'color' | 'className' | 'onClick' | 'onKeyDown' | 'tabIndex'
    > {}

export const ColorCircle = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IColorCircleProps>
>(({ color, size, ...otherProps }, forwardedRef) => {
  const backgroundProps = isGradient(color)
    ? {
        backgroundImage: color,
      }
    : {
        backgroundColor: color,
      };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={BorderRadius.Pill}
      width={size}
      height={size}
      color={BaseColor.White}
      overflow="hidden"
      ref={forwardedRef}
      {...backgroundProps}
      {...otherProps}
    />
  );
});

ColorCircle.displayName = 'ColorCircle';
