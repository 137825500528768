import { mergeProps } from '@react-aria/utils';
import React from 'react';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { colorTextDefault, colorTextDefaultInverse } from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { ConditionalWrapper } from '../_utils/ConditionalWrapper.tsx';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { StyledCountStatus, StyledCountStatusFloating } from './components/StyledCountStatus.tsx';
import { CountStatusPlusPlacement, CountStatusStyle } from './types.ts';

export type CountStatusProps = Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> &
  Readonly<{
    countStatusStyle?: CountStatusStyle;
    color?: BaseColor;
    value: number;
    plusPlacement?: CountStatusPlusPlacement;
  }>;

// This formats the number with thousands separator so that every three digits from the end are separated using a space
// e.g. 10000000 => 10 000 000
const formatNumber = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const CountStatus = React.forwardRef<HTMLSpanElement, CountStatusProps>(
  (
    {
      color = colorTextDefault,
      countStatusStyle = 'default',
      plusPlacement = 'none',
      tooltipPlacement = 'top',
      tooltipText,
      value,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <Tooltip text={tooltipText} placement={tooltipPlacement}>
        <StyledCountStatus
          tabIndex={tooltipText?.length ? 0 : undefined}
          ref={forwardedRef}
          $color={countStatusStyle === 'inverse' ? colorTextDefaultInverse : color}
          countStatusStyle={countStatusStyle}
          isFocusVisible={isFocusVisible}
          {...mergeProps(restProps, focusProps)}
        >
          <ConditionalWrapper
            condition={countStatusStyle === 'floating'}
            wrapper={(children) => (
              <StyledCountStatusFloating>{children}</StyledCountStatusFloating>
            )}
          >
            <Box
              component="span"
              display="inline-flex"
              alignItems="center"
              color={countStatusStyle === 'inverse' ? colorTextDefaultInverse : color}
              typography={Typography.TitleLarge}
            >
              {plusPlacement === 'before' && '+'}
              {formatNumber(value)}
              {plusPlacement === 'after' && '+'}
            </Box>
          </ConditionalWrapper>
        </StyledCountStatus>
      </Tooltip>
    );
  },
);

CountStatus.displayName = 'CountStatus';
