import { UseTransitionProps, animated, config, useTransition } from '@react-spring/web';
import Tippy, { TippyProps } from '@tippyjs/react';
import React, { useContext, PropsWithChildren } from 'react';
import { useDynamicDebounce } from '../../hooks/useDynamicDebounce.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { colorTextDefaultInverse } from '../../tokens/decision/colors.ts';
import { tooltipZIndex } from '../../tokens/decision/zIndex.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { BoxShadow } from '../../tokens/quarks/shadow.ts';
import { Spacing, gridUnit } from '../../tokens/quarks/spacing.ts';
import { LetterSpacing, Typography } from '../../tokens/quarks/typography.ts';
import { px } from '../../tokens/utils/utils.ts';
import { Placement } from '../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ShortcutSet } from '../ShortcutSet/ShortcutSet.tsx';
import { PortalContainerContext } from '../_contexts/PortalContainerContext.tsx';
import { Arrow } from './components/Arrow.tsx';

const defaultMaxGridUnitsWidth = 50;
const defaultShowDelay = 700;
const defaultHideDelay = 20;
const defaultDelay = [defaultShowDelay, defaultHideDelay] as [number, number];
const defaultOffset = [0, Spacing.S] as [number, number];

export type TooltipTippyOptions = Pick<
  TippyProps,
  'appendTo' | 'offset' | 'onBeforeUpdate' | 'popperOptions' | 'zIndex' | 'arrow' | 'aria'
>;

const TooltipText = ({
  text,
}: {
  readonly text: string;
}) => (
  <Box
    component="span"
    display="inline-flex"
    alignItems="center"
    flexGrow={1}
    flexShrink={1}
    flexBasis="auto"
    overflowWrap="anywhere"
    textAlign="left"
    typography={Typography.TitleMedium}
    letterSpacing={LetterSpacing.L}
    color={colorTextDefaultInverse}
    {...getDataUiComponentAttribute(TooltipText)}
  >
    {text}
  </Box>
);

TooltipText.displayName = 'TooltipText';

export type TooltipProps = {
  readonly delay?: [showDelay: number, hideDelay: number];
  readonly text: string | null | undefined;
  readonly id?: string;
  readonly placement: Placement | null;
  readonly children: React.ReactElement;
  readonly shortcuts?: string | null;
  /** Cannot be greater than default maximum width */
  readonly maxGridUnitsWidth?: number | null;
  readonly tippyOptions?: TooltipTippyOptions;
  readonly visible?: boolean;
};

const defaultPlacement = 'top' satisfies Placement;

const bottomInitialPosition = `translate(0, ${px(Spacing.XL)})`;
const topInitialPosition = `translate(0, ${px(-1 * Spacing.XL)})`;
const leftInitialPosition = `translate(${px(Spacing.XL)}, 0)`;
const rightInitialPosition = `translate(${px(-1 * Spacing.XL)}, 0)`;

const getInitialTransformValue = (placement: Placement) => {
  switch (placement) {
    case 'bottom':
    case 'bottom-end':
    case 'bottom-start':
      return topInitialPosition;
    case 'top':
    case 'top-end':
    case 'top-start':
      return bottomInitialPosition;
    case 'left':
    case 'left-end':
    case 'left-start':
      return leftInitialPosition;
    case 'right':
    case 'right-end':
    case 'right-start':
      return rightInitialPosition;
  }
};

const getTransitionProps = (placement: Placement | null = defaultPlacement): UseTransitionProps => {
  const initialTransform = getInitialTransformValue(placement ?? defaultPlacement);
  return {
    from: {
      opacity: 0,
      transform: initialTransform,
    },
    enter: {
      opacity: 1,
      transform: 'translate(0px, 0px)',
    },
    leave: {
      opacity: 0,
      transform: initialTransform,
    },
    delay: 100,
    config: config.default,
  };
};

const TooltipTransition = ({
  placement,
  isVisible,
  children,
}: PropsWithChildren<{
  readonly isVisible: boolean;
  readonly placement: Placement;
}>) => {
  const transitionProps = getTransitionProps(placement);
  const transition = useTransition(isVisible, transitionProps);

  return transition(
    (style, item) =>
      item && (
        <animated.div
          style={style}
          css={`
            display: flex;
            width: max-content;
          `}
        >
          {children}
        </animated.div>
      ),
  );
};

export const defaultTooltipTippyOptions = {
  arrow: true,
  zIndex: tooltipZIndex,
  offset: defaultOffset,
} satisfies TooltipTippyOptions;

export const Tooltip = ({
  delay = defaultDelay,
  text,
  id,
  shortcuts,
  placement,
  children,
  maxGridUnitsWidth,
  tippyOptions,
  visible: visibleFromProps,
}: PropsWithChildren<TooltipProps>) => {
  // RTE needs the child DOM nodes structure stable when tooltip changes from valid tooltip to empty one (thus it must be rendered, for now,
  // even when there is no content and rendering only children would normally suffice) E.g. dropdown menus in add link button in rich text
  // when tooltip is being hidden while the menu is open suffers from such DOM change
  const hasContent = !!text;

  const getDelayForVisibleProp = <TValue extends TooltipProps['visible']>(
    current: TValue,
    newValue: TValue,
  ): number => {
    if (!current !== !newValue) {
      return newValue ? delay[0] : delay[1];
    }
    return 0;
  };

  const isTooltipVisible = useDynamicDebounce(visibleFromProps, getDelayForVisibleProp);
  const [isCurrentlyVisible, setIsCurrentlyVisible] = React.useState(false);

  const { portalContainerRef } = useContext(PortalContainerContext);

  const useTippyOptions: TippyProps = {
    ...defaultTooltipTippyOptions,
    appendTo: portalContainerRef.current ?? document.body,
    ...tippyOptions,
  };

  const cappedGridUnitsWidth =
    Math.min(maxGridUnitsWidth ?? defaultMaxGridUnitsWidth, defaultMaxGridUnitsWidth) * gridUnit;

  return (
    <Tippy
      {...useTippyOptions}
      allowHTML={!!shortcuts}
      delay={delay}
      disabled={!hasContent}
      placement={placement ?? undefined}
      visible={isTooltipVisible}
      onShow={() => setIsCurrentlyVisible(true)}
      onHide={() => setIsCurrentlyVisible(false)}
      render={(attrs) => {
        const dataPlacement = attrs['data-placement'] as Placement;
        return (
          text &&
          dataPlacement && (
            <TooltipTransition
              key={dataPlacement}
              placement={dataPlacement}
              isVisible={isCurrentlyVisible}
            >
              <Box
                id={id}
                position="relative"
                flexGrow={1}
                flexShrink={0}
                flexBasis="auto"
                display="flex"
                gap={Spacing.XS}
                backgroundColor={BaseColor.Gray100}
                borderRadius={BorderRadius.S}
                boxShadow={BoxShadow.L}
                maxWidth={cappedGridUnitsWidth}
                paddingX={Spacing.S}
                paddingY={Spacing.XS}
                whiteSpace="pre-wrap"
                pointerEvents="none"
                role="tooltip"
                {...getDataUiComponentAttribute(Tooltip)}
                {...attrs}
              >
                <TooltipText text={text} />
                {shortcuts && <ShortcutSet shortcuts={shortcuts} shortcutStyle="inverse" />}
                <Arrow placement={dataPlacement} />
              </Box>
            </TooltipTransition>
          )
        );
      }}
    >
      {children}
    </Tippy>
  );
};

Tooltip.displayName = 'Tooltip';
