import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { MouseEvent, MouseEventHandler, ReactElement, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import {
  colorBackgroundDisabledComplementary,
  colorBackgroundHover,
  colorIconDefault,
  colorIconDefaultInverse,
  colorTextDefault,
} from '../../tokens/decision/colors.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { BaseColor, Color } from '../../tokens/quarks/colors.ts';
import { GradientType } from '../../tokens/quarks/gradients.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { px } from '../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { getReadableTextColor } from '../../utils/getReadableColor.ts';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { useOptionalTooltip } from '../Tooltip/useOptionalTooltip.ts';
import { TagContextProvider } from './TagContext.tsx';
import {
  StyledLabelButton,
  StyledLabelButtonWrapper,
  StyledRemoveButton,
  StyledRemoveButtonWrapper,
} from './components/StyledButtons.tsx';
import { TagCountStatus } from './components/TagCountStatus.tsx';
import { TagIcon } from './components/TagIcon.tsx';
import { TagLabel } from './components/TagLabel.tsx';
import { getRemoveButtonColor, getTagBackground } from './helpers.ts';
import { RemovalState } from './removalStateEnum.ts';

export interface IStyledTagProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  readonly $disabled?: boolean;
  readonly background: Color | GradientType;
  readonly removalState?: RemovalState;
}

export type TagProps = {
  readonly background: Color;
  readonly backgroundHover?: Color;
  readonly borderColor?: Color;
  readonly children?: OneOrMore<ReactElement | boolean | null | undefined>;
  readonly customTooltipText?: string;
  readonly disabled?: boolean;
  readonly disableTabulator?: boolean;
  /** Label is not rendered if you provide children. In that case you need to use `Tag.Label` component. */
  readonly label?: string;
  readonly onLabelClick?: MouseEventHandler<HTMLElement>;
  readonly onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
  readonly removalState?: RemovalState;
  readonly removeButtonTooltipText?: string;
};

const StyledTag = styled.div<IStyledTagProps>`
  background: ${({ background, $disabled }) => getTagBackground(background, $disabled).toString()};
  border-radius: ${px(BorderRadius.Pill)};
  display: inline-flex;
  max-width: 100%;
`;

const TagComponent = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<TagProps>>(
  (
    {
      background,
      backgroundHover = colorBackgroundHover,
      borderColor = BaseColor.Transparent,
      children,
      disabled = false,
      disableTabulator,
      customTooltipText,
      label = '',
      onLabelClick,
      onRemoveClick,
      removalState = RemovalState.Allowed,
      removeButtonTooltipText,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const labelRef = useRef<HTMLSpanElement | null>(null);
    const [labelText, setLabelText] = useState(label);

    const resolvedBackground = disabled ? colorBackgroundDisabledComplementary : background;
    const labelColor = getReadableTextColor(resolvedBackground);
    // We have to use labelColor here because different default colors (for text and icons) might result in "black" icon and "white" label
    const iconColor = labelColor === colorTextDefault ? colorIconDefault : colorIconDefaultInverse;
    const labelButtonFocusProps = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});
    const removeButtonFocusProps = useOurFocusRing();

    // We're using `useOptionalTooltip` instead of OptionalTooltip component because we need it to use our own ref — truncatedElementRef.
    const { tooltipProps: optionalTooltipProps } = useOptionalTooltip(
      labelRef,
      labelText,
      customTooltipText,
    );

    const labelId = useId();

    return (
      <TagContextProvider
        labelColor={labelColor}
        iconColor={iconColor}
        labelRef={labelRef}
        labelText={labelText}
        setLabelText={setLabelText}
      >
        <StyledTag
          $disabled={disabled}
          background={background}
          {...otherProps}
          {...getDataUiComponentAttribute(Tag)}
        >
          <Tooltip
            placement="top"
            visible={isHovered || labelButtonFocusProps.isFocusVisible}
            {...optionalTooltipProps}
          >
            <StyledLabelButtonWrapper isRemovable={removalState !== RemovalState.NoRemoval}>
              <StyledLabelButton
                as={onLabelClick ? 'button' : 'div'}
                backgroundHover={backgroundHover}
                borderColor={borderColor}
                disabled={disabled}
                interactive={!!onLabelClick}
                isFocused={labelButtonFocusProps.isFocusVisible}
                isRemovable={removalState !== RemovalState.NoRemoval}
                onClick={(e: MouseEvent<HTMLElement>) => {
                  if (!disabled && onLabelClick) {
                    onLabelClick(e);
                  }
                }}
                ref={forwardedRef}
                tabIndex={disabled || disableTabulator ? -1 : undefined}
                type={onLabelClick ? 'button' : undefined}
                {...mergeProps(labelButtonFocusProps.focusProps, hoverProps)}
              >
                {children ?? <TagLabel id={labelId}>{label}</TagLabel>}
              </StyledLabelButton>
            </StyledLabelButtonWrapper>
          </Tooltip>
          {removalState !== RemovalState.NoRemoval && (
            <Tooltip text={removeButtonTooltipText} placement="top">
              <StyledRemoveButtonWrapper>
                <StyledRemoveButton
                  aria-label="Remove"
                  aria-describedby={labelId}
                  backgroundHover={backgroundHover}
                  borderColor={borderColor}
                  disabled={removalState === RemovalState.Disabled}
                  isFocused={removeButtonFocusProps.isFocusVisible}
                  onClick={onRemoveClick}
                  tabIndex={
                    removalState === RemovalState.Disabled || disableTabulator ? -1 : undefined
                  }
                  type="button"
                  interactive
                  {...removeButtonFocusProps.focusProps}
                >
                  <Icons.ModalClose
                    color={getRemoveButtonColor(
                      removalState === RemovalState.Disabled,
                      disabled,
                      labelColor,
                    )}
                    size={IconSize.S}
                  />
                </StyledRemoveButton>
              </StyledRemoveButtonWrapper>
            </Tooltip>
          )}
        </StyledTag>
      </TagContextProvider>
    );
  },
);

TagComponent.displayName = 'Tag';

const tagComposition = {
  CountStatus: TagCountStatus,
  Label: TagLabel,
  Icon: TagIcon,
} as const;

export const Tag = Object.assign(TagComponent, tagComposition);
