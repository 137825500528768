import React, { PropsWithChildren } from 'react';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BaseNotificationBar, NotificationBarProps } from './components/BaseNotificationBar.tsx';
import { NotificationBarType } from './notificationBarType.ts';

const createFlavoredNotificationBar = <TProps extends PropsWithChildren<NotificationBarProps>>(
  flavor: NotificationBarType,
) => {
  const FlavoredNotificationBar = React.forwardRef(
    (props: PropsWithChildren<TProps>, forwardedRef: React.Ref<HTMLDivElement>) => (
      <BaseNotificationBar
        notificationBarType={flavor}
        ref={forwardedRef}
        {...getDataUiComponentAttribute(FlavoredNotificationBar)}
        {...props}
      />
    ),
  );

  FlavoredNotificationBar.displayName = `NotificationBar${flavor}`;

  return FlavoredNotificationBar;
};

export type NotificationBarAlertProps = Omit<NotificationBarProps, 'hideIcon' | 'onDismiss'> &
  Required<Pick<NotificationBarProps, 'onDismiss'>>;
export const NotificationBarAlert =
  createFlavoredNotificationBar<NotificationBarAlertProps>('Alert');

export type NotificationBarBlankProps = Omit<NotificationBarProps, 'hideIcon'>;
export const NotificationBarBlank =
  createFlavoredNotificationBar<NotificationBarBlankProps>('Blank');

export type NotificationBarFriendlyWarningProps = NotificationBarProps;
export const NotificationBarFriendlyWarning =
  createFlavoredNotificationBar<NotificationBarFriendlyWarningProps>('FriendlyWarning');

export type NotificationBarInfoProps = NotificationBarProps;
export const NotificationBarInfo = createFlavoredNotificationBar<NotificationBarInfoProps>('Info');

export type NotificationBarInfoEmphasisProps = NotificationBarProps;
export const NotificationBarInfoEmphasis =
  createFlavoredNotificationBar<NotificationBarInfoEmphasisProps>('InfoEmphasis');

export type NotificationBarWarningProps = Omit<NotificationBarProps, 'hideIcon'>;
export const NotificationBarWarning =
  createFlavoredNotificationBar<NotificationBarWarningProps>('Warning');

export type NotificationBarSuccessProps = Omit<NotificationBarProps, 'hideIcon'>;
export const NotificationBarSuccess =
  createFlavoredNotificationBar<NotificationBarSuccessProps>('Success');
