import { useEffect, useRef } from 'react';

/**
 * Returns a value received in previous render.
 * On the first render returns current value.
 *
 * @deprecated Using this hook is not recommended, as it prevents us from adopting React Compiler.
 * Using useRef for rendering is not allowed: https://react.dev/reference/react/useRef
 * Use `usePrevious` when needed, or ideally, avoid using it at all: https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 */
export function useWrongPrevious<T>(prop: T): T {
  const previous = useRef(prop);
  useEffect(() => {
    previous.current = prop;
  });
  return previous.current;
}
