import styled, { css } from 'styled-components';
import { widthFocus } from '../../../tokens/decision/focus.ts';
import { spacingHierarchyIndent } from '../../../tokens/decision/spacing.ts';
import { shadowAlertFocusStyles, shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { menuItemPaddingY } from '../decisionTokens.ts';
import { MenuItemState } from '../menuItemState.ts';
import {
  getActiveBackgroundColor,
  getActiveColor,
  getBackgroundColor,
  getBackgroundHoverColor,
  getColor,
  getCursor,
  getHoverColor,
} from '../utils.ts';

export interface IStyledMenuItemProps {
  readonly $isFocusVisible: boolean;
  readonly $state: MenuItemState;
  readonly $nestedLevel?: number;
  readonly $isHovered?: boolean;
  readonly $isExpanded?: boolean;
}

export const StyledMenuItem = styled.div.attrs<IStyledMenuItemProps>(({ $nestedLevel }) =>
  $nestedLevel
    ? {
        style: {
          paddingLeft: $nestedLevel * spacingHierarchyIndent + Spacing.L,
        },
      }
    : undefined,
)<IStyledMenuItemProps>`
  position: relative;
  display: block;
  padding: ${px(menuItemPaddingY)} ${px(Spacing.L)};
  padding-left: ${px(Spacing.L)};
  cursor: ${(props) => getCursor(props.$state)};
  user-select: none;
  background-color: ${({ $isHovered, $isExpanded, $state }) =>
    $isHovered || $isExpanded ? getBackgroundHoverColor($state) : getBackgroundColor($state)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color ${transition250};
  
  && {
    // This is to override Anchor styles. See Anchor.tsx.
    color: ${({ $isHovered, $isExpanded, $state }) =>
      $isHovered || $isExpanded ? getHoverColor($state) : getColor($state)};
  }

  &&:active {
    color: ${(props) => getActiveColor(props.$state)};
    background-color: ${(props) => getActiveBackgroundColor(props.$state)};
  }

  ${({ $isFocusVisible, $state }) =>
    $isFocusVisible &&
    css`
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: ${px(widthFocus)};
      right: ${px(widthFocus)};
      bottom: ${px(widthFocus)};
      left: ${px(widthFocus)};
      pointer-events: none;
      ${$state === 'error' ? shadowAlertFocusStyles : shadowFocusStyles};
    }
  `};
`;
