import { groupBy } from '../utils/arrayUtils.ts';
import { Collection } from '../utils/collectionUtils.ts';
import { isElement } from './typeguards.ts';

export function getAbsoluteTopOffset(
  element: HTMLElement | null,
  scrollParent?: HTMLElement | null,
): number | null {
  if (!element) {
    return null;
  }

  const elementScrollParent = scrollParent ?? getScrollParent(element);
  const scrollTop = elementScrollParent ? elementScrollParent.scrollTop : 0;
  return element.getBoundingClientRect().top + scrollTop;
}

export function getElementOffset(query: string): number | null {
  const element = document.body.querySelector<HTMLElement>(query);
  return getAbsoluteTopOffset(element);
}

export function getParent(
  element: Element | null | Text,
  parentPredicate: (parentElement: Element) => boolean,
): HTMLElement | null {
  if (!isElement(element)) {
    return null;
  }

  let parentElement: Element | null = element;

  while (parentElement.parentElement) {
    parentElement = parentElement.parentElement;

    if (parentElement.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    if (parentElement.nodeName === 'BODY') {
      return null;
    }

    if (parentPredicate(parentElement) && isElement(parentElement)) {
      return parentElement;
    }
  }

  return null;
}

export function getSelfOrParent(
  element: Element,
  predicate: (element: HTMLElement) => boolean,
): HTMLElement | null {
  const result = isElement(element) && predicate(element) ? element : getParent(element, predicate);
  return result;
}

function allowsScrolling(style: CSSStyleDeclaration): boolean {
  return /(auto|scroll)/.test(`${style.overflow}${style.overflowY}${style.overflowX}`);
}

function isScrollable(element: Element): boolean {
  return element instanceof Element && allowsScrolling(getComputedStyle(element));
}

export function getScrollParent(element: Element | null | Text): Element {
  return getParent(element, isScrollable) || document.body;
}

function scrollParentWithOffset(element: Element, behavior: ScrollBehavior, topOffset: number) {
  const scrollParent = getScrollParent(element);
  const top = element.getBoundingClientRect().top;
  const parentRect = scrollParent.getBoundingClientRect();

  scrollParent.scrollTo({
    top: scrollParent.scrollTop - (parentRect.top - top + topOffset),
    behavior,
  });
}

export function scrollToView(
  element: Element,
  position: ScrollLogicalPosition = 'center',
  behavior: ScrollBehavior = 'smooth',
  topOffset?: number,
): void {
  if (topOffset) {
    scrollParentWithOffset(element, behavior, topOffset);
  } else {
    element.scrollIntoView({
      behavior,
      block: position,
    });
  }
}

export enum ScrollAlignment {
  Bottom = 'bottom',
  None = 'none',
  Top = 'top',
}

export type ScrollElementsToViewOptions = {
  // Requested alignment of the elements to the viewport
  readonly alignment?: ScrollAlignment;
  // Alignment of the elements to the viewport in case they cannot fit the viewport
  readonly noFitAlignment?: ScrollAlignment;
  readonly behavior?: ScrollBehavior;
  readonly bottomOffset?: number;
  readonly offset?: number;
  readonly topOffset?: number;
  readonly idealTop?: number;
  readonly scrollParent?: HTMLElement;
};

// Ensures that the given elements are scrolled to the view with a minimum scrolling effort.
// Each group of elements sharing the same scrolling parent initiates scrolling on its own.
// If already in the view, the scrolling doesn't occur.
// Optional offset parameters define extra space which should be present after the scrolling
// in order to keep the element(s) away from the window edge to make the user experience more friendly.
export function scrollElementsToView(
  elements: ReadonlyArray<HTMLElement>,
  options?: ScrollElementsToViewOptions,
): void {
  const topOffset = options?.topOffset ?? options?.offset ?? 0;
  const bottomOffset = options?.bottomOffset ?? options?.offset ?? 0;
  const behavior = options?.behavior ?? 'smooth';
  const alignment = options?.alignment ?? ScrollAlignment.None;

  Collection.getEntries(
    groupBy(elements, (element: HTMLElement) => options?.scrollParent ?? getScrollParent(element)),
  ).forEach(([scrollParent, group]) => {
    const topMostElement = group.toSorted(
      (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top,
    )[0] as HTMLElement;
    const top = topMostElement.getBoundingClientRect().top;

    const bottomMostElement = group.toSorted(
      (a, b) => b.getBoundingClientRect().bottom - a.getBoundingClientRect().bottom,
    )[0] as HTMLElement;
    const bottom = bottomMostElement.getBoundingClientRect().bottom;

    const parentRect = scrollParent.getBoundingClientRect();

    const idealTop = options?.idealTop;
    if (idealTop) {
      const isIdealTopInView = idealTop > parentRect.top && idealTop < parentRect.bottom;
      if (isIdealTopInView) {
        const elementHeight = bottom - top;
        const idealBottom = idealTop + elementHeight;
        const isIdealBottomInView = idealBottom > parentRect.top && idealBottom < parentRect.bottom;
        if (isIdealBottomInView) {
          const targetIdealTop = scrollParent.scrollTop - (idealTop - top);
          if (targetIdealTop) {
            scrollParent.scrollTo({
              top: targetIdealTop,
              behavior,
            });
            return;
          }
        }
      }
    }

    const isTopOutOfView = parentRect.top > top || top > parentRect.bottom;
    const isBottomOutOfView = parentRect.bottom < bottom;
    const isInView = !isTopOutOfView && !isBottomOutOfView;

    const targetTopForTopAlignment = scrollParent.scrollTop - (parentRect.top - top + topOffset);
    const targetTopForBottomAlignment =
      scrollParent.scrollTop - (parentRect.bottom - bottom - bottomOffset);

    const canFitParent = bottom - top <= parentRect.height;

    if (alignment !== ScrollAlignment.None) {
      const finalAlignment = canFitParent ? alignment : (options?.noFitAlignment ?? alignment);

      // Always scroll in case the specific alignment is requested
      scrollParent.scrollTo({
        top:
          finalAlignment === ScrollAlignment.Top
            ? targetTopForTopAlignment
            : targetTopForBottomAlignment,
        behavior,
      });
      return;
    }

    if (isInView) {
      return;
    }

    if (!canFitParent) {
      // Cannot fit view as a whole - put the top most element to the window top
      scrollParent.scrollTo({
        top: targetTopForTopAlignment,
        behavior,
      });
    } else if (isTopOutOfView) {
      // Top is off - put the top most element to the window top
      scrollParent.scrollTo({
        top: targetTopForTopAlignment,
        behavior,
      });
    } else {
      // Bottom is off - put the bottom most element to the window bottom
      scrollParent.scrollTo({
        top: targetTopForBottomAlignment,
        behavior,
      });
    }
  });
}

export function isWholeNodeVisibleVertically(element: HTMLElement | null | undefined): boolean {
  if (!element) {
    return false;
  }
  const scrollParent = getScrollParent(element);
  if (!scrollParent) {
    return false;
  }

  const rectNodeElement = element.getBoundingClientRect();
  const rectScrollParent = scrollParent.getBoundingClientRect();

  return (
    rectNodeElement.top >= rectScrollParent.top && // is not over scroll parent
    rectNodeElement.bottom <= rectScrollParent.bottom // is not under scroll parent
  );
}

// partially visible, at least 1 pixel is visible
export function isElementVisible(
  element: HTMLElement,
  topOffset: number = 0,
  bottomOffset: number = 0,
): boolean {
  const scrollParent = getScrollParent(element);
  if (!scrollParent) {
    return false;
  }

  const nodeCbr = element.getBoundingClientRect();
  const scrollParentCbr = scrollParent.getBoundingClientRect();

  return !(
    nodeCbr.top + nodeCbr.height <= scrollParentCbr.top + topOffset ||
    nodeCbr.top >= scrollParentCbr.top + scrollParentCbr.height - bottomOffset ||
    nodeCbr.left + nodeCbr.width <= scrollParentCbr.left ||
    nodeCbr.left >= scrollParentCbr.left + scrollParentCbr.width
  );
}

export function isElementFullyVisible(element: Element): boolean {
  const scrollParent = getScrollParent(element);
  if (!scrollParent) {
    return false;
  }

  const nodeCbr = element.getBoundingClientRect();
  const scrollParentCbr = scrollParent.getBoundingClientRect();
  return (
    nodeCbr.top >= scrollParentCbr.top &&
    nodeCbr.bottom <= scrollParentCbr.bottom &&
    nodeCbr.left >= scrollParentCbr.left &&
    nodeCbr.right <= scrollParentCbr.right
  );
}

export function focusAtTheStart(inputElement: HTMLInputElement | HTMLTextAreaElement | null): void {
  if (!inputElement || inputElement.type === 'email') {
    return;
  }

  inputElement.focus();
  inputElement.setSelectionRange(0, 0);
}

export function focusAtTheEnd(inputElement: HTMLInputElement | HTMLTextAreaElement | null): void {
  if (!inputElement || inputElement.type === 'email') {
    return;
  }

  const textLength = inputElement.value.length;

  inputElement.focus();
  inputElement.setSelectionRange(textLength, textLength);
}

export const getNodesClosestParentElement = (node: Node | null): HTMLElement | null => {
  if (!node) {
    return null;
  }
  if (isElement(node)) {
    return node;
  }

  return node.parentElement ?? null;
};

const FocusableElementSelector = `
a[href]:not([tabindex='-1']),
area[href]:not([tabindex='-1']),
input:not([disabled]):not([tabindex='-1']),
select:not([disabled]):not([tabindex='-1']),
textarea:not([disabled]):not([tabindex='-1']),
button:not([disabled]):not([tabindex='-1']),
iframe:not([tabindex='-1']),
[tabindex]:not([tabindex='-1']),
[contentEditable=true]:not([tabindex='-1'])
`;

export function placeFocusToElement(element: HTMLElement, options?: FocusOptions) {
  // Try to focus the element so that its parent gets also properly focused if needed.
  if (element.matches(FocusableElementSelector)) {
    element.focus(options);
  } else {
    const focusableElement = element.querySelector(FocusableElementSelector);
    if (isElement(focusableElement)) {
      focusableElement.focus(options);
    }
  }
}

export const isElementVerticallyOverflowing = (element: HTMLElement | undefined | null): boolean =>
  element ? element.offsetHeight < element.scrollHeight : false;

export const isElementHorizontallyOverflowing = (
  element: HTMLElement | undefined | null,
): boolean => (element ? element.offsetWidth < element.scrollWidth : false);
