import { useAttachRef } from '@kontent-ai/hooks';
import { useLink } from '@react-aria/link';
import { mergeProps } from '@react-aria/utils';
import { PressEvent } from '@react-types/shared';
import React, { PropsWithChildren } from 'react';

interface IOutwardLinkProps {
  readonly className?: string;
  readonly contentEditable?: boolean;
  readonly href: string;
  readonly onClick?: (e: PressEvent) => void;
  readonly stopClickPropagation?: boolean;
  readonly title?: string;
}

export const OutwardLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<IOutwardLinkProps>
>(({ children, href, onClick, stopClickPropagation, ...otherProps }, forwardedRef) => {
  const { refObject, refToForward } = useAttachRef(forwardedRef);
  const { linkProps } = useLink(
    {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
      onPress: (event) => {
        if (!stopClickPropagation) {
          event.continuePropagation();
        }

        onClick?.(event);
      },
    },
    refObject,
  );

  return (
    <a ref={refToForward} {...mergeProps(otherProps, linkProps)}>
      {children ?? href}
    </a>
  );
});

OutwardLink.displayName = 'OutwardLink';
