import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor, Color } from '../../../tokens/quarks/colors.ts';
import { GradientType } from '../../../tokens/quarks/gradients.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import {
  DataUiCLElement,
  getDataUiCLElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { getReadableTextColor } from '../../../utils/getReadableColor.ts';
import { Icon } from '../../Icons/types.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';

export interface IWorkflowStepConfig extends TooltipPropsExtension {
  readonly background: Color | GradientType;
  readonly icon?: Icon | null;
  readonly name: string;
}

export interface IWorkflowStepProps extends IWorkflowStepConfig {
  readonly isOverflowing?: boolean;
}

export const workflowStepBorderRadius = BorderRadius.Pill;
export const workflowStepHeight = Size.XS;

const Container = styled.div<{
  readonly background: IWorkflowStepConfig['background'];
  readonly $color: BaseColor;
}>`
  ${Typography.TitleMedium};

  height: ${px(workflowStepHeight)};
  padding: 0 ${px(Spacing.M)};
  border-radius: ${px(workflowStepBorderRadius)};

  ${({ background, $color }) => css`
    background: ${background.toString()};
    color: ${$color};
  `};
`;

const StyledRow = styled.div`
  height: 100%;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WorkflowStep = forwardRef<HTMLDivElement, IWorkflowStepProps>(
  (
    {
      background,
      icon,
      isOverflowing,
      name,
      tooltipText,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'right',
      tooltipShortcuts,
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});

    const IconComponent = icon;

    const isNameVisible = !IconComponent || !isOverflowing;
    const isTooltipVisible = isFocusVisible || isHovered;

    return (
      <Tooltip
        text={isTooltipVisible ? tooltipText : undefined} // make the tooltip disappear immediately
        placement={tooltipPlacement}
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        shortcuts={tooltipShortcuts}
        visible={isTooltipVisible}
      >
        <Container
          background={background}
          $color={getReadableTextColor(background)}
          ref={forwardedRef}
          {...mergeProps(hoverProps, focusProps)}
          {...getDataUiCLElementAttribute(DataUiCLElement.WorkflowStepContainer)}
        >
          <Row alignY="center" component={StyledRow} noWrap spacing={Spacing.XS}>
            {IconComponent && (
              <Column width="content">
                <IconComponent size={IconSize.S} />
              </Column>
            )}
            {isNameVisible ? (
              <Column width="content">
                <Name>
                  <span data-enable-grammarly="false">{name}</span>
                </Name>
              </Column>
            ) : (
              <SrOnly>{name}</SrOnly>
            )}
          </Row>
        </Container>
      </Tooltip>
    );
  },
);

WorkflowStep.displayName = 'WorkflowStep';
