import { Placement } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import React, { PropsWithChildren, RefObject } from 'react';
import { Box } from '../../../../layout/Box/Box.tsx';
import { Column } from '../../../../layout/Row/Column.tsx';
import { Row } from '../../../../layout/Row/Row.tsx';
import { Spacing } from '../../../../tokens/quarks/spacing.ts';
import { ObjectWithDataAttribute } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { Badge } from '../../../Badge/Badge.tsx';
import { QuinaryButton } from '../../../Button/QuinaryButton/QuinaryButton.tsx';
import { Icon } from '../../../Icons/types.ts';
import { Menu } from '../../../Menu/Menu.tsx';

export type NavigationBarQuinaryMenuButtonProps = {
  readonly onMenuOpenChange?: () => void;
  readonly placement?: Placement;
  readonly trigger: {
    readonly icon: Icon;
    readonly name: string;
    readonly fullWidth?: boolean;
    readonly showName?: boolean;
  };
  readonly menuDataAttributes?: ObjectWithDataAttribute;
  readonly role?: HTMLDivElement['role'];
  readonly showBadge?: boolean;
  readonly showName?: boolean;
};

export const NavigationBarQuinaryMenuButton: React.FC<
  PropsWithChildren<NavigationBarQuinaryMenuButtonProps>
> = ({
  children,
  menuDataAttributes,
  onMenuOpenChange,
  placement = 'right bottom',
  role,
  trigger: { name, icon, showName, fullWidth, ...otherTriggerProps },
  showBadge,
}) => {
  return (
    <Menu onOpenChange={onMenuOpenChange}>
      <Menu.Trigger>
        {(ref, triggerProps, isOpen) => (
          <QuinaryButton
            activated={isOpen}
            ref={ref as RefObject<HTMLButtonElement>}
            tooltipPlacement="right"
            tooltipText={isOpen ? '' : name}
            onClick={triggerProps.onClick}
            fullWidth={fullWidth}
            {...mergeProps(triggerProps, otherTriggerProps)}
          >
            <Row alignY="center" spacingX={fullWidth ? Spacing.S : Spacing.None}>
              <Column>
                <QuinaryButton.Icon icon={icon} screenReaderText={name} />
              </Column>
              {showName && (
                <Column>
                  <Box>{name}</Box>
                </Column>
              )}
              {showBadge && fullWidth ? (
                <Column>
                  <Badge badgeStyle="default" />
                </Column>
              ) : showBadge ? (
                <Column>
                  <QuinaryButton.Badge />
                </Column>
              ) : null}
            </Row>
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List placement={placement} {...menuDataAttributes} role={role}>
        {children}
      </Menu.List>
    </Menu>
  );
};

NavigationBarQuinaryMenuButton.displayName = 'NavigationBarQuinaryMenuButton';
