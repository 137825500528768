import { KeyboardEvent, RefObject } from 'react';
import { MenuItemProps } from '../MenuItem/MenuItem.tsx';
import { Descendant } from './contexts/DescendantContext.tsx';

function isElementPreceding(a: Element, b: Element) {
  // eslint-disable-next-line no-bitwise
  return Boolean(b.compareDocumentPosition(a) & Node.DOCUMENT_POSITION_PRECEDING);
}

export const findDOMIndex = (
  items: ReadonlyArray<Descendant>,
  elementRef: RefObject<HTMLElement>,
) => {
  if (!elementRef.current) return -1;
  if (!items.length) return 0;

  let length = items.length;
  // Most of the time, the new item will be added at the end of the list, so we
  // do a findIndex in reverse order, instead of wasting time searching the
  // index from the beginning.
  while (length--) {
    const currentElement = items[length]?.elementRef.current;
    if (!currentElement) continue;
    if (isElementPreceding(currentElement, elementRef.current)) {
      return length + 1;
    }
  }
  return 0;
};

export const tabbableProps: Pick<MenuItemProps, 'role' | 'tabIndex' | 'onKeyDown'> = {
  role: 'listitem',
  tabIndex: 0,
  // prevent Menu component from modifying tab flow
  onKeyDown: (e: KeyboardEvent): void => {
    if (e.key === 'Tab' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.stopPropagation();
    }
  },
};
