import { useAttachRef } from '@kontent-ai/hooks';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import styled from 'styled-components';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { Tooltip } from './Tooltip.tsx';
import { useOptionalTooltip } from './useOptionalTooltip.ts';

type RenderProp = (truncatedElementRef: React.RefCallback<HTMLElement>) => React.ReactElement;

type OptionalTooltipProps = {
  readonly customRenderText?: RenderProp;
  readonly placement: TooltipPropsExtension['tooltipPlacement'];
  /**
   * Tooltip text and text merge into tooltip when text gets truncated.
   */
  readonly text: string;
  /**
   * tooltipText is shown always. If truncation occurs, text is appended.
   */
  readonly tooltipText?: TooltipPropsExtension['tooltipText'];
  readonly visible?: boolean;
} & (
  | {
      readonly children: React.ReactNode;
      readonly customRenderText?: never;
    }
  | {
      readonly children?: never;
      readonly customRenderText: RenderProp;
    }
);

const StyledOptionalTooltipBody = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OptionalTooltip = ({
  children,
  customRenderText,
  placement = 'bottom',
  text,
  tooltipText,
  visible,
  ...otherProps
}: OptionalTooltipProps) => {
  const { refObject, refToForward } = useAttachRef<HTMLElement>(null);

  const { tooltipProps } = useOptionalTooltip(refObject, text, tooltipText);

  return (
    <Tooltip placement={placement} visible={visible} {...mergeProps(tooltipProps, otherProps)}>
      {customRenderText ? (
        customRenderText(refToForward)
      ) : (
        <StyledOptionalTooltipBody ref={refToForward}>{children}</StyledOptionalTooltipBody>
      )}
    </Tooltip>
  );
};

OptionalTooltip.displayName = 'OptionalTooltip';
