import { useAttachRef } from '@kontent-ai/hooks';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import React, { HTMLAttributes, useState, useEffect } from 'react';
import { LinkProps } from 'react-router-dom';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { colorAlertIcon, colorIconDefaultInverse } from '../../tokens/decision/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Anchor, RouterLink } from '../Anchor/Anchor.tsx';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { MenuItemBody, MenuItemBodyProps } from './MenuItemBody.tsx';
import { StyledLeadingElement } from './components/StyledLeadingElement.tsx';
import { IStyledMenuItemProps, StyledMenuItem } from './components/StyledMenuItem.tsx';
import { MenuItemState } from './menuItemState.ts';

const disablingAttributes = {
  'aria-disabled': true,
  disabled: true,
};

type MenuItemWithoutHTMLAttrsProps = Pick<
  MenuItemBodyProps,
  'renderLabel' | 'renderComplementaryText'
> &
  Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> &
  Readonly<{
    className?: string;
    complementaryText?: string;
    disabledFocus?: boolean;
    href?: string;
    isExpanded?: boolean;
    isHovered?: boolean;
    leadingElement?: React.ReactNode;
    linkPath?: LinkProps['to'];
    menuItemState: MenuItemState;
    nestedLevel?: number;
    onPress?: () => void;
    text: string;
    trailingElements?: React.ReactNode;
  }>;

export type MenuItemProps = MenuItemWithoutHTMLAttrsProps &
  Omit<HTMLAttributes<HTMLDivElement | HTMLAnchorElement>, 'className' | 'onClick'>;

export const MenuItem = React.forwardRef<HTMLDivElement | HTMLAnchorElement, MenuItemProps>(
  (
    {
      autoFocus,
      className,
      complementaryText,
      disabledFocus,
      href,
      isExpanded,
      isHovered,
      leadingElement,
      linkPath,
      menuItemState = 'default',
      nestedLevel,
      onPress,
      renderComplementaryText,
      renderLabel,
      text,
      tooltipPlacement = 'bottom',
      tooltipText,
      trailingElements,
      ...restProps
    },
    ref,
  ) => {
    const { isFocusVisible, focusProps } = useFocusRing();

    const isInteractionDisabled = menuItemState === 'disabled' || menuItemState === 'readonly';
    const isInErrorState = menuItemState === 'error' || menuItemState === 'error-selected';
    const [menuItemIsTruncated, setMenuItemIsTruncated] = useState(false);
    const { refObject, refToForward } = useAttachRef(ref);

    const displayTooltipText =
      tooltipText ||
      (menuItemIsTruncated ? `${text} ${complementaryText ? complementaryText : ''}` : undefined);

    useEffect(() => {
      if (autoFocus) {
        refObject.current?.focus();
      }
    }, [autoFocus, refObject]);

    const item = (
      <Row alignY="center" spacing={Spacing.S} noWrap>
        {isInErrorState && (
          <Column width="content">
            <StyledLeadingElement>
              <Icons.ExclamationTriangleInverted
                color={
                  menuItemState === 'error' && !(isHovered || isExpanded)
                    ? colorAlertIcon
                    : colorIconDefaultInverse
                }
              />
            </StyledLeadingElement>
          </Column>
        )}
        {leadingElement && (
          <Column width="content">
            <StyledLeadingElement>{leadingElement}</StyledLeadingElement>
          </Column>
        )}
        <Tooltip placement={tooltipPlacement} text={displayTooltipText}>
          <MenuItemBody
            complementaryText={complementaryText}
            isExpanded={isExpanded}
            isHovered={isHovered}
            renderComplementaryText={renderComplementaryText}
            renderLabel={renderLabel}
            state={menuItemState}
            text={text}
            onTruncated={setMenuItemIsTruncated}
          />
        </Tooltip>

        {trailingElements && (
          <Column width="content">
            <Row alignY="center" spacing={Spacing.S} noWrap>
              {React.Children.map(
                trailingElements,
                (child) => child && <Column width="content">{child}</Column>,
              )}
            </Row>
          </Column>
        )}
      </Row>
    );

    const commonProps: IStyledMenuItemProps = {
      $isFocusVisible: isFocusVisible,
      $nestedLevel: nestedLevel,
      $state: menuItemState,
      $isHovered: isHovered,
      $isExpanded: isExpanded,
      ...mergeProps(
        {
          className,
          onClick: isInteractionDisabled ? undefined : onPress,
        },
        disabledFocus ? {} : focusProps,
        restProps,
      ),
      ...(isInteractionDisabled ? disablingAttributes : {}),
      ...getDataUiComponentAttribute(MenuItem),
    };

    if (href && !isInteractionDisabled) {
      return (
        <StyledMenuItem
          as={Anchor}
          target="_blank"
          rel="noopener noreπferrer"
          href={href}
          ref={refToForward}
          {...commonProps}
        >
          {item}
        </StyledMenuItem>
      );
    }

    if (linkPath && !isInteractionDisabled) {
      return (
        <StyledMenuItem
          as={RouterLink}
          to={linkPath}
          innerRef={refToForward}
          tabIndex={disabledFocus ? -1 : undefined}
          {...commonProps}
        >
          {item}
        </StyledMenuItem>
      );
    }

    const customMenuItemProps = {
      tabIndex: disabledFocus ? undefined : 0,
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onPress?.();
        }
      },
    };
    const mergedMenuItemProps = mergeProps(
      {
        ...(isInteractionDisabled ? {} : customMenuItemProps),
      },
      commonProps,
    );

    return (
      <StyledMenuItem ref={refToForward} {...mergedMenuItemProps}>
        {item}
      </StyledMenuItem>
    );
  },
);
MenuItem.displayName = 'MenuItem';
