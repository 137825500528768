import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
export const SvgLayers = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#clip0_2244_9721)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.479492 5.59999L8.00015 0.738281L15.5208 5.59999L8.00015 10.4617L0.479492 5.59999ZM2.32149 5.59999L8.00015 9.27094L13.6788 5.59999L8.00015 1.92903L2.32149 5.59999ZM0.593172 7.2098C0.753677 6.98509 1.06595 6.93305 1.29066 7.09355L8.00004 11.886L14.7094 7.09355C14.9341 6.93305 15.2464 6.98509 15.4069 7.2098C15.5674 7.4345 15.5154 7.74678 15.2907 7.90728L8.00004 13.1149L0.70942 7.90728C0.484713 7.74678 0.432668 7.4345 0.593172 7.2098ZM1.29066 9.59355C1.06595 9.43305 0.753677 9.48509 0.593172 9.7098C0.432668 9.9345 0.484713 10.2468 0.70942 10.4073L8.00004 15.6149L15.2907 10.4073C15.5154 10.2468 15.5674 9.9345 15.4069 9.7098C15.2464 9.48509 14.9341 9.43305 14.7094 9.59355L8.00004 14.386L1.29066 9.59355Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_2244_9721">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
