import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { CollapsibleVerticalMenuItemIcon } from '../../VerticalMenu/components/CollapsibleVerticalMenuItemIcon.tsx';
import { ISelectItem } from '../types.ts';
import { IOptionProps, Option } from './Option.tsx';

export interface ICollapsibleOptionProps<TItem extends ISelectItem<TItem>>
  extends IOptionProps<TItem> {
  readonly isDisabled?: boolean;
  readonly disabledTooltipText?: string;
}

export const CollapsibleOption = React.forwardRef(
  <TItem extends ISelectItem<TItem>>(
    { disabledTooltipText, isDisabled, item, state, ...otherProps }: ICollapsibleOptionProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ref = React.useRef(null);
    const { pressProps } = usePress({
      ref,
      isDisabled,
      onPress: item.hasChildNodes
        ? () => {
            state.toggleKey(item.key);
          }
        : undefined,
    });
    const { hoverProps, isHovered } = useHover({});

    const isExpanded = state.expandedKeys.has(item.key);

    return (
      <Option
        item={item}
        leadingElement={
          <Tooltip
            text={disabledTooltipText}
            placement="top-start"
            visible={isDisabled && isHovered}
          >
            <Box
              cursor={isDisabled ? 'not-allowed' : undefined}
              ref={ref}
              display="inline-flex"
              visibility={item.hasChildNodes ? undefined : 'hidden'}
              {...mergeProps(pressProps, hoverProps)}
            >
              <CollapsibleVerticalMenuItemIcon
                hasChildren={item.hasChildNodes}
                isExpanded={isExpanded}
              />
            </Box>
          </Tooltip>
        }
        ref={forwardedRef}
        state={state}
        {...otherProps}
      />
    );
  },
);

CollapsibleOption.displayName = 'CollapsibleOption';
