import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { useState, useId } from 'react';
import styled from 'styled-components';
import { ColorCircle } from '../../../containers/ColorCircle/ColorCircle.tsx';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Box, BoxBorder } from '../../../layout/Box/Box.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { DarkGradient, GradientAngle, GradientFunction } from '../../../tokens/quarks/gradients.ts';
import { BoxShadow } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement } from '../../../types/placement.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import { ConditionalWrapper } from '../../_utils/ConditionalWrapper.tsx';
import { AvatarSize } from '../avatarSize.ts';
import { Initials } from './Initials.tsx';

export interface IBaseAvatarProps {
  readonly backgroundGradient: GradientFunction;
  readonly boxShadow?: BoxShadow;
  readonly image?: string;
  readonly initials: string;
  readonly label: string;
  readonly name?: string;
  readonly size: AvatarSize;
  readonly tooltipPlacement?: Placement;
}

interface IInjectedProps {
  readonly children: JSX.Element;
  readonly 'aria-describedby': string | undefined;
  readonly isFocusVisible: boolean;
}

export interface IBaseAvatarComponentProps extends IBaseAvatarProps {
  readonly renderContainer: (props: IInjectedProps) => JSX.Element;
  readonly tooltipText?: string;
}

// We offset the image to hide antialiasing artifacts between the image and underlying initials
const imageOffset = 1;

type ImageProps = Readonly<{
  $isVisible: boolean;
  $size: number;
}>;

const Image = styled.img.attrs<ImageProps>((props) => ({
  $size: props.$size + 2 * imageOffset,
}))<ImageProps>`
  position: absolute;
  left: ${px(-imageOffset)};
  top: ${px(-imageOffset)};

  display: block;
  width: ${({ $size }) => px($size)};
  height: ${({ $size }) => px($size)};
  border: ${BoxBorder.LowEmphasis};
  border-radius: ${px(BorderRadius.Pill)};

  // override generic 'img' styles which might break the component
  max-width: ${({ $size }) => px($size)} !important;
  margin: 0 !important;

  overflow: hidden;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: none;
`;

const StyledBaseAvatarComponentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  `;

const StyledAvatarName = styled.span`
  ${Typography.LabelLarge}
  color: ${colorTextDefault};
  padding-left: ${px(Spacing.S)};
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BaseAvatarComponent: React.FC<IBaseAvatarComponentProps> = ({
  backgroundGradient,
  image,
  initials,
  label,
  name,
  renderContainer,
  size,
  tooltipPlacement = 'bottom',
  tooltipText,
}) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();
  const { isHovered, hoverProps } = useHover({});
  const shouldShowTooltip = isFocusVisible || isHovered;
  const tooltipId = useId();

  const [showImage, setShowImage] = useState(!!image);
  return (
    <Tooltip
      placement={tooltipPlacement}
      tippyOptions={disabledAriaTippyOptions}
      text={tooltipText}
      id={tooltipId}
      visible={shouldShowTooltip}
    >
      {renderContainer({
        children: (
          <ConditionalWrapper
            condition={!!name}
            wrapper={(children) => (
              <StyledBaseAvatarComponentWrapper>{children}</StyledBaseAvatarComponentWrapper>
            )}
          >
            <Box position="relative">
              <ColorCircle
                color={
                  backgroundGradient(GradientAngle.ToBottom) ??
                  DarkGradient.GrayDark(GradientAngle.ToBottom)
                }
                size={size}
              >
                <Initials $size={size} aria-hidden>
                  {size === AvatarSize.XS ? initials[0] : initials}
                </Initials>
                <SrOnly>{label}</SrOnly>
              </ColorCircle>
              {image && (
                <Image
                  $isVisible={showImage}
                  $size={size}
                  src={image}
                  alt={label}
                  onLoad={() => setShowImage(true)}
                  onError={() => setShowImage(false)}
                  aria-hidden
                />
              )}
            </Box>
            {name && <StyledAvatarName>{name}</StyledAvatarName>}
          </ConditionalWrapper>
        ),
        isFocusVisible,
        'aria-describedby': shouldShowTooltip ? tooltipId : undefined,
        ...mergeProps(focusProps, hoverProps),
      })}
    </Tooltip>
  );
};

BaseAvatarComponent.displayName = 'BaseAvatarComponent';
