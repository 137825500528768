import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { MouseEventHandler, PropsWithChildren, useId } from 'react';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import { A11yLabelingPropsExtension } from '../../_utils/ariaLabelingProps.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { QuinaryButtonBadge } from './QuinaryButtonBadge.tsx';
import { QuinaryButtonChevron } from './QuinaryButtonChevron.tsx';
import { QuinaryButtonIcon } from './QuinaryButtonIcon.tsx';
import { QuinaryButtonStyle } from './QuinaryButtonStyle.ts';
import { StyledQuinaryButtonWrapper } from './StyledQuinaryButtonWrapper.tsx';
import { StyledQuinaryButton } from './components/StyledQuinaryButton.tsx';

type Props = TooltipPropsExtension &
  A11yLabelingPropsExtension & {
    readonly activated?: boolean;
    readonly autoFocus?: boolean;
    readonly buttonStyle?: QuinaryButtonStyle;
    readonly disabled?: boolean;
    readonly disableTabulator?: boolean;
    readonly fullWidth?: boolean;
    readonly id?: string;
    readonly onClick?: MouseEventHandler;
    readonly tooltipText: string;
  };

const QuinaryButtonWithRef = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      activated,
      autoFocus,
      buttonStyle = 'default',
      children,
      disableTabulator,
      disabled,
      fullWidth,
      onClick,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipShortcuts,
      tooltipText,
      'aria-describedby': ariaDescribedby = '',
      ...otherProps
    },
    ref,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { hoverProps, isHovered } = useHover({});

    const tooltipId = useId();
    const shouldShowTooltip = isFocusVisible || isHovered;

    return (
      <Tooltip
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        placement={tooltipPlacement}
        shortcuts={tooltipShortcuts}
        tippyOptions={disabledAriaTippyOptions}
        text={tooltipText}
        id={tooltipId}
        visible={shouldShowTooltip}
      >
        <StyledQuinaryButtonWrapper>
          <StyledQuinaryButton
            activated={activated}
            autoFocus={autoFocus}
            buttonStyle={buttonStyle}
            disabled={disabled}
            isFocusVisible={isFocusVisible}
            onClick={onClick}
            ref={ref}
            tabIndex={disableTabulator ? -1 : undefined}
            type="button"
            aria-describedby={mergeAriaDescribedBy(
              ariaDescribedby,
              shouldShowTooltip ? tooltipId : null,
            )}
            fullWidth={fullWidth}
            {...mergeProps(focusProps, hoverProps, otherProps)}
            {...getDataUiComponentAttribute(QuinaryButton)}
          >
            {children}
          </StyledQuinaryButton>
        </StyledQuinaryButtonWrapper>
      </Tooltip>
    );
  },
);

QuinaryButtonWithRef.displayName = 'QuinaryButton';

const quinaryButtonComposition = {
  Badge: QuinaryButtonBadge,
  Icon: QuinaryButtonIcon,
  Chevron: QuinaryButtonChevron,
} as const;

export const QuinaryButton = Object.assign(QuinaryButtonWithRef, quinaryButtonComposition);
