import { isMac } from '@react-aria/utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import hotkeys, { KeyHandler } from 'hotkeys-js';
import React, { useRef, useEffect } from 'react';
import { ComponentLibraryGlobals } from '../globals/componentLibraryGlobals.ts';

export type HotkeysCallback = KeyHandler;

export const ShortcutsConfig = {
  Backspace: 'backspace',
  Enter: 'enter',
  Escape: 'escape',
  Tab: 'tab',
  ShiftTab: 'shift+tab',
  ShiftF10: 'shift+f10',
  Space: 'space',

  ControlC: isMac() ? 'ctrl+c,cmd+c' : 'ctrl+c',
  ControlEnter: isMac() ? 'ctrl+enter,cmd+enter' : 'ctrl+enter',
  ControlS: isMac() ? 'ctrl+s,cmd+s' : 'ctrl+s',

  AltUp: isMac() ? 'option+up,alt+up' : 'alt+up',
  AltDown: isMac() ? 'option+up,alt+down' : 'alt+down',

  Up: 'up',
  Down: 'down',
  Left: 'left',
  Right: 'right',
  PageUp: 'pageup',
  PageDown: 'pagedown',
  Home: 'home',
  End: 'end',
} as const satisfies Dictionary<string>;

const knownShortcuts: ReadonlySet<ShortcutsConfig> = new Set(Object.values(ShortcutsConfig));

export type ShortcutsConfig = (typeof ShortcutsConfig)[keyof typeof ShortcutsConfig];

export type HotkeysConfig = Partial<Record<ShortcutsConfig, KeyHandler>>;

// Allow shortcuts in inputs
hotkeys.filter = () => true;

const normalizeHandlers = (handlers: HotkeysConfig): HotkeysConfig => {
  const shortcuts = Object.entries(handlers);
  const allowedShortcuts = shortcuts.filter(([shortcut, handler]) => {
    if (!knownShortcuts.has(shortcut)) {
      ComponentLibraryGlobals.logError(
        `Keyboard shortcut '${shortcut}' is not recognized as valid and will be ignored.`,
      );

      return false;
    }

    return !!handler;
  });
  const individualHandlers = allowedShortcuts.flatMap(([shortcut, handler]) =>
    shortcut.split(',').map((individualShortcut: ShortcutsConfig) => [individualShortcut, handler]),
  );

  return Object.fromEntries(individualHandlers);
};

type Options<TInstance extends HTMLElement> = Readonly<{
  excludedElementRefs?: readonly React.RefObject<TInstance>[];
  isDisabled?: boolean;
  ref: React.RefObject<TInstance> | null;
}>;

export const useHotkeys = <TInstance extends HTMLElement>(
  handlers: HotkeysConfig,
  { ref, excludedElementRefs = [], isDisabled }: Options<TInstance>,
) => {
  const normalizedHandlers = normalizeHandlers(handlers);
  const keys = Object.keys(normalizedHandlers).join(',');

  const shortCutHandlerRef = useRef<KeyHandler | undefined>(undefined);
  const totallyStableHandlerRef = useRef<KeyHandler>((...args) => {
    return shortCutHandlerRef.current?.(...args);
  });

  const ignoreEventWhen = (event: KeyboardEvent): boolean => {
    return excludedElementRefs.some((excludedRef) =>
      excludedRef.current?.contains(event.target as Node),
    );
  };

  shortCutHandlerRef.current = (event, hotkeyInfo) => {
    const { key: shortcut } = hotkeyInfo;

    if (isDisabled || ignoreEventWhen(event)) {
      return;
    }

    const handler = normalizedHandlers[shortcut as ShortcutsConfig];

    if (!handler) {
      ComponentLibraryGlobals.logError(`undefined shortcut handler encountered: ${shortcut}`);
      return;
    }

    handler(event, hotkeyInfo);
  };

  useEffect(() => {
    hotkeys(keys, { element: ref?.current }, totallyStableHandlerRef.current);

    return () => {
      hotkeys.unbind(keys, totallyStableHandlerRef.current);
    };
  }, [ref, keys]);
};
