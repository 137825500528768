import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { BorderRadius } from '../../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../../tokens/quarks/typography.ts';
import { px } from '../../../../tokens/utils/utils.ts';
import { quinaryButtonHeight, quinaryButtonHorizontalPadding } from '../../decisionTokens.ts';
import { QuinaryButton } from '../QuinaryButton.tsx';
import { getButtonBadgeVariables } from '../QuinaryButtonBadge.tsx';
import { QuinaryButtonStyle } from '../QuinaryButtonStyle.ts';
import {
  quinaryButtonIconColorDisabledVarName,
  quinaryButtonIconColorHoverVarName,
  quinaryButtonIconColorVarName,
} from '../constants.ts';
import { getQuinaryButtonTokens } from '../utils/stylingUtils.ts';

interface IStyledButtonProps extends Pick<ComponentProps<typeof QuinaryButton>, 'autoFocus'> {
  readonly isFocusVisible?: boolean;
  readonly fullWidth?: boolean;
  readonly activated?: boolean;
  readonly buttonStyle: QuinaryButtonStyle;
}

export const StyledQuinaryButton = styled.button.attrs(getQuinaryButtonTokens)<IStyledButtonProps>`
  ${({
    backgroundColor,
    backgroundColorHover,
    backgroundColorDisabled,
    textColor,
    textColorHover,
    textColorDisabled,
    iconColor,
    iconColorHover,
    iconColorDisabled,
    isFocusVisible,
    fullWidth,
  }) => css`
    ${quinaryButtonIconColorVarName}: ${iconColor};
    ${quinaryButtonIconColorHoverVarName}: ${iconColorHover};
    ${quinaryButtonIconColorDisabledVarName}: ${iconColorDisabled};
    ${getButtonBadgeVariables};

    ${Typography.LabelLarge}
    position: relative;

    display: flex;
    align-items: center;
    justify-content: ${fullWidth ? 'flex-start' : 'center'};
    gap: ${px(Spacing.S)};
    text-wrap: nowrap;

    min-width: ${fullWidth ? '100%' : px(quinaryButtonHeight)};
    height: ${px(quinaryButtonHeight)};

    padding-left: ${px(quinaryButtonHorizontalPadding)};
    padding-right: ${px(quinaryButtonHorizontalPadding)};
    border: none;
    border-radius: ${px(BorderRadius.S)};

    color: ${textColor};
    background: ${backgroundColor};
    transition: background ${transition250};
    transition-property: background, color;

    ${isFocusVisible && shadowFocusStyles};

    &:enabled {
      &:hover {
        background-color: ${backgroundColorHover};
        color: ${textColorHover};
      }

      &:active {
        background-color: ${backgroundColorHover};
        color: ${textColorHover};
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: ${textColorDisabled};
      background-color: ${backgroundColorDisabled};
    }
  `};
`;
