import styled from 'styled-components';

/**
 * Styles inspired by the ReactSpectrum switch's hidden input
 * https://react-spectrum.adobe.com/react-spectrum/Switch.html
 */
export const StyledToggleInput = styled.input.attrs({ type: 'checkbox', role: 'switch' })`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: .0001;
  z-index: 1;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
