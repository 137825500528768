import styled, { css } from 'styled-components';
import { borderWidthHighEmphasis } from '../../../tokens/decision/border.ts';
import { colorAccent, colorIconDefault } from '../../../tokens/decision/colors.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';

type IProps = {
  readonly iconName: IconName;
  readonly isActive?: boolean;
};

const StyledBox = styled.li<{ readonly isActive?: boolean }>`
  position: relative;
  height: ${px(Size.S)};
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-right: ${px(borderWidthHighEmphasis)} solid transparent;

    ${({ isActive }) => isActive && css`border-right-color: ${colorAccent}`};
  }
`;

export const RefinedNavigationIconPlaceholder = ({ iconName, isActive }: IProps) => {
  const Icon = Icons[iconName];

  return (
    <StyledBox isActive={isActive}>
      <Icon size={IconSize.S} color={colorIconDefault} />
    </StyledBox>
  );
};
