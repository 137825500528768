import React, { Dispatch, SetStateAction, useMemo, Ref } from 'react';
import { BaseColor } from '../../tokens/quarks/colors.ts';

type TagContextState = {
  readonly labelColor: BaseColor;
  readonly iconColor: BaseColor;
  readonly labelRef: Ref<HTMLSpanElement>;
  readonly labelText: string;
  readonly setLabelText: Dispatch<SetStateAction<string>>;
};

export const TagContext = React.createContext<TagContextState | undefined>(undefined);

type TagContextProviderProps = TagContextState;

export const TagContextProvider: React.FC<React.PropsWithChildren<TagContextProviderProps>> = ({
  children,
  labelColor,
  iconColor,
  labelRef,
  labelText,
  setLabelText,
}) => {
  const contextValue = useMemo(
    () => ({
      labelColor,
      iconColor,
      labelRef,
      labelText,
      setLabelText,
    }),
    [labelText, labelColor, labelRef, iconColor, setLabelText],
  );

  return <TagContext.Provider value={contextValue}>{children}</TagContext.Provider>;
};
