import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { useCheckboxGroupItem } from '@react-aria/checkbox';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { FocusEventHandler, ReactNode, useId } from 'react';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CheckboxGroupContext } from '../CheckboxGroup/CheckboxGroupContext.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../Tooltip/constants.ts';
import { A11yLabelingPropsExtension } from '../_utils/ariaLabelingProps.ts';
import { mergeAriaDescribedBy } from '../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { CheckboxCaption } from './components/CheckboxCaption.tsx';
import { CheckboxSymbol } from './components/CheckboxSymbol.tsx';
import { StyledCheckboxInput } from './components/StyledCheckboxInput.tsx';
import { StyledCheckboxLabel, labelOffset } from './components/StyledCheckboxLabel.tsx';
import { StyledCheckboxWrapper } from './components/StyledCheckboxWrapper.tsx';
import { CheckboxState } from './types.ts';
import { getCheckboxStatus } from './utils/getCheckboxStatus.ts';
import { CheckboxStylingProps, checkboxPadding } from './utils/stylingUtils.ts';

type CheckboxItemProps = Pick<TooltipPropsExtension, 'tooltipPlacement' | 'tooltipText'> &
  Pick<A11yLabelingPropsExtension, 'aria-label' | 'aria-labelledby'> & {
    readonly autoFocus?: boolean;
    readonly caption?: ReactNode | null;
    readonly checkboxState: CheckboxState;
    readonly children?: ReactNode;
    readonly className?: string;
    readonly indeterminate?: boolean;
    readonly name?: string;
    readonly onBlur?: FocusEventHandler<HTMLInputElement>;
    readonly onFocus?: FocusEventHandler<HTMLInputElement>;
    readonly tabIndex?: number;
    readonly value: string;
  };

export const CheckboxItem = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<CheckboxItemProps>
>(
  (
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabeledBy,
      autoFocus,
      caption,
      checkboxState,
      children,
      indeterminate = false,
      onBlur,
      onFocus,
      tooltipText = '',
      tooltipPlacement = 'top',
      className,
      tabIndex,
      name,
      value,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkboxGroupContext = useEnsuredContext(CheckboxGroupContext);
    const isChecked = checkboxGroupContext.state.isSelected(value);
    const isDisabled = checkboxState === 'disabled' || checkboxGroupContext.state.isDisabled;
    const checkboxStatus = getCheckboxStatus(isChecked, indeterminate);

    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});
    const { refToForward, refObject } = useAttachRef(forwardedRef);

    const captionId = useId();
    const tooltipId = useId();

    const shouldShowTooltip = isFocusVisible || isHovered;
    const { inputProps } = useCheckboxGroupItem(
      {
        'aria-describedby': mergeAriaDescribedBy(
          caption ? captionId : null,
          shouldShowTooltip ? tooltipId : null,
        ),
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabeledBy,
        autoFocus,
        isIndeterminate: indeterminate,
        isDisabled,
        onFocus,
        onBlur,
        children,
        value,
      },
      checkboxGroupContext.state,
      refObject,
    );

    const checkboxStylingProps: CheckboxStylingProps = {
      checkboxState: isDisabled ? 'disabled' : checkboxState,
      checkboxStatus,
    };

    return (
      <Stack align="start" {...getDataUiComponentAttribute(CheckboxItem)} {...restProps}>
        <Inline>
          <Tooltip
            placement={tooltipPlacement}
            text={tooltipText}
            visible={shouldShowTooltip}
            id={tooltipId}
            tippyOptions={disabledAriaTippyOptions}
          >
            <StyledCheckboxWrapper
              isFocusVisible={isFocusVisible}
              className={className}
              {...checkboxStylingProps}
              {...hoverProps}
            >
              <StyledCheckboxInput
                tabIndex={tabIndex}
                ref={refToForward}
                name={name}
                {...checkboxStylingProps}
                {...mergeProps(inputProps, focusProps)}
              />
              <Row component="span" alignY="start" noWrap>
                <Column component="span" width="content">
                  <CheckboxSymbol {...checkboxStylingProps} />
                </Column>
                {!!children && (
                  <Column width="fit-content">
                    <Box
                      paddingRight={checkboxPadding}
                      css={`
                        padding-top: ${labelOffset};
                        padding-bottom: ${labelOffset};
                      `}
                    >
                      <StyledCheckboxLabel {...checkboxStylingProps}>
                        {children}
                      </StyledCheckboxLabel>
                    </Box>
                  </Column>
                )}
              </Row>
            </StyledCheckboxWrapper>
          </Tooltip>
        </Inline>
        {!!caption && <CheckboxCaption captionId={captionId} caption={caption} />}
      </Stack>
    );
  },
);

CheckboxItem.displayName = 'CheckboxItem';
