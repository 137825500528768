import { useState } from 'react';

/**
 * Returns the previous value of a variable.
 * Initially, it returns the current value, otherwise it returns the last (different) value.
 */
export function usePrevious<T>(value: T): T {
  const [current, setCurrent] = useState(value);
  const [previous, setPrevious] = useState(value);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
}
