import { isElementHorizontallyOverflowing, isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import { ComponentProps, RefObject, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { Tooltip } from './Tooltip.tsx';

const getCombinedTooltipText = (
  text: string,
  tooltipText: TooltipPropsExtension['tooltipText'],
  isOverflowing: boolean,
): string => {
  if (isOverflowing) {
    if (tooltipText?.length) {
      return `${tooltipText}\n${text}`;
    }
    return text;
  }

  return tooltipText ?? '';
};

export type UseOptionalTooltipResult = {
  /** Spread this on a regular Tooltip component. */
  readonly tooltipProps: Pick<ComponentProps<typeof Tooltip>, 'text'>;
};

export const useOptionalTooltip = <TElement extends HTMLElement = HTMLElement>(
  truncatedElementRef: RefObject<TElement>,
  text: string,
  tooltipText?: TooltipPropsExtension['tooltipText'],
): UseOptionalTooltipResult => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useResizeObserver({
    ref: truncatedElementRef,
    onResize: () =>
      setIsOverflowing(
        isElementHorizontallyOverflowing(truncatedElementRef.current) ||
          isElementVerticallyOverflowing(truncatedElementRef.current),
      ),
  });

  const combinedTooltipText = getCombinedTooltipText(text, tooltipText, isOverflowing);

  const tooltipProps = {
    text: combinedTooltipText,
  };

  return {
    tooltipProps,
  };
};
